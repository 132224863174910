<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-card
                title="Liste des pièces"
                no-body
              >
                Liste des pièces

              </b-card>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-right mb-1 ml-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.modal-prevent-closing
                type="submit"
                variant="success"
                class="mb-1 float-right"
                size="sm"
              >
                Ajouter une pièce
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="pieces"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status }}
            </b-badge>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-b-modal.modal-prevent-image-closing
                @click="getImage(data.item)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="text-primary"
                />
                <span class="align-middle ml-50 text-primary">Voir</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-b-modal.modal-prevent-closing
                @click="getPiece(data.item)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="text-warning"
                />
                <span class="align-middle ml-50 text-warning">Modifier</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-b-modal.suppression
                @click="getPiece(data.item)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="text-danger"
                />
                <span class="align-middle ml-50 text-danger">Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </template></b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="pieces.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      no-close-on-backdrop
      button-size="sm"
      :title="form.id != null ? 'Modifier une pièce jointe' :'Ajouter une pièce jointe'"
      :busy="documentProcess"
      :ok-title="form.id != null ? 'Modifier' :'Ajouter'"
      cancel-title="Fermer"
      cancel-variant="secondary"
      ok-variant="success"
      @ok="handleOk"
    >
      <validation-observer ref="accountFilesRules">
        <form @submit.prevent="handleSubmitModal">

          <b-form-group
            label="Type de pièce"
            label-for="piece"
          >
            <validation-provider
              #default="{ errors }"
              name="type de pièce"
              rules="required"
            >
              <v-select
                id="piece"
                v-model="form.document_type"
                label="name"
                size="sm"
                :state="errors.length > 0 ? false : null"
                :options="documents"
                placeholder="Choisir un profil"
                :reduce="(documents) => documents.slug"
                class="select-size-sm"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Numéro de la pièce"
            label-for="piece_number"
          >
            <validation-provider
              #default="{ errors }"
              name="numéro de pièce"
              rules="required"
            >
              <b-form-input
                id="piece_number"
                v-model="form.numero_piece"
                size="sm"
                :state="errors.length > 0 ? false : null"
                placeholder="Numéro de la pièce"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Date d'expiration"
            label-for="date_expiration"
            label-size="sm"
          >
            <validation-provider
              #default="{ errors }"
              name="date d'expiration"
              rules="required"
            >
              <flat-pickr
                id="date_expiration"
                v-model="form.date_expiration"
                class="form-control"
                size="sm"
                :config="config"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Pièce justificative"
            label-for="fichier"
            label-size="sm"
          >
            <validation-provider
              #default="{ errors }"
              name="pièce justificative"
              rules="required"
            >
              <b-form-file
                id="fichier"
                ref="file-input"
                v-model="form.file"
                accept="image/*"
                size="sm"
                placeholder="Choisir un fichier"
                @change="handleImageSelected"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-prevent-image-closing"
      ref="my-modal"
      button-size="sm"
      title="Pièce jointe"
      ok-only
      ok-title="Fermer"
    >
      <b-card
        :img-src="imageUrl"
        img-top
        img-alt=""
        class="position-static"
      />

    </b-modal>
    <!-- form modal -->
    <b-modal
      id="suppression"
      ref="my-modal"
      cancel-title="Non"
      ok-title="Oui"
      title="Suppression de pièce"
      modal-class="modal-danger"
      ok-variant="danger"
      button-size="sm"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleDeleteOk"
    >
      <form @submit.prevent="handleDeleteSubmitModal">
        <h6>Etes-vous sûr de vouloir supprimer ce fichier?</h6>

      </form>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import {
  ref, onMounted, reactive,
} from '@vue/composition-api'
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BFormInput,
  BBadge, BSpinner, BModal, VBModal, BFormFile,

} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, password, image, ext, mimes,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import usePieces from '@/services/media/mediaService'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'
// eslint-disable-next-line import/no-cycle
import useDocuments from '@/services/admin/documentService'
import flatPickr from 'vue-flatpickr-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import { French } from 'flatpickr/dist/l10n/fr'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    flatPickr,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BBadge,
    vSelect,

    BSpinner,

  },
  directives: {
    'b-modal': VBModal,

    Ripple,
  },
  data() {
    return {
      required,
      password,
      email,
      confirmed,
      image,
      ext,
      mimes,
    }
  },
  setup() {
    const {
      imageUrl, imageFile, handleImageSelected, uploadFile, fileName, deleteFile,
    } = useImageUpload()
    const {
      pieces, getPieces, createOrUpdatePiece, pieceSuccess, loader, deletePiece,
    } = usePieces()
    const {
      getDocument, document, documentProcess, documents, getDocuments,
    } = useDocuments()
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M  Y',
      altInput: true,
      dateFormat: 'Y-m-d',
      locale: French, // locale for this instance only
    }
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'document.name', label: 'Document', sortable: true },
      { key: 'numero_piece', label: 'N° Piece', sortable: true },
      { key: 'date_expiration', label: 'Date Expiration', sortable: true },
      { key: 'status', label: 'Status Compte', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      numero_piece: '',
      date_expiration: '',
      image: null,
      file: null,
      id: null,
      document_type: '',
    })
    const clearPiece = () => {
      form.numero_piece = ''
      form.date_expiration = ''
      form.document_type = ''
      form.image = null
      form.file = null
      imageUrl.value = ''
      fileName.value = ''
      form.id = null
    }

    /* eslint-disable global-require */
    const items = pieces
    onMounted(async () => {
      await getPieces()
      await getDocuments()

      // Set the initial number of items
      totalRows.value = items.length
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    const store = async () => {
      const result = imageFile.value instanceof File
      if (result) {
        const data = new FormData()
        data.append('fichier', imageFile.value)
        if (!form.image === null) {
          await deleteFile(form.image)
        }
        await uploadFile(data)
        form.image = fileName.value
      }

      await getDocument(form.document_type)
      form.document_type = document.value.id
      await createOrUpdatePiece({ ...form })
      console.clear()
      form.id = null
      if (pieceSuccess.value) {
        clearPiece()
      }
    }
    const getImage = item => {
      imageUrl.value = item.image_url
      console.clear()
    }
    const deleteImage = async () => {
      await deletePiece(form.id)
      if (pieceSuccess.value) {
        await deleteFile(form.image)
      }
      clearPiece()
      console.clear()
    }
    // Obtenir une piece
    const getPiece = async item => {
      form.document_type = item.document.slug
      form.numero_piece = item.numero_piece
      form.date_expiration = item.date_expiration
      form.id = item.id
      form.file = item.image
      form.image = item.image
    }
    const resolveUserStatusVariant = status => {
      if (status === 'Envoyé') return 'warning'
      if (status === 'Validé') return 'success'
      if (status === 'Rejeté') return 'danger'
      return 'primary'
    }

    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      deleteImage,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      items,
      config,
      fields,
      store,
      handleImageSelected,
      onFiltered,
      pieces,
      documents,
      form,
      imageUrl,
      documentProcess,
      getPiece,
      loader,
      clearPiece,
      getImage,
      pieceSuccess,
      resolveUserStatusVariant,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    handleDeleteOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleDeleteSubmitModal()
    },
    handleDeleteSubmitModal() {
      // Exit when the form isn't valid

      this.deleteImage()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },

    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.accountFilesRules.validate().then(async success => {
        if (success) {
          // Push the name to submitted names
          await this.store(this.form)
          if (this.pieceSuccess) {
            this.$refs['file-input'].reset()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          }
        }
      })
    },

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
